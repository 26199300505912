























































































































































import { InputSetups } from '@/mixins/input-setups'
import { Poll, PostType, Quiz } from '@/includes/types/Post.types'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'

import Component from 'vue-class-component'
import { Mixins, Prop, VModel, Watch } from 'vue-property-decorator'

@Component
export default class Quizes extends Mixins(InputSetups, UseFields) {
  @VModel() message !: Poll | Quiz

  @Prop({ type: Boolean, default: false }) disabled !: boolean

  newOption = ''

  questionsFrontend:Array<{ value: string, visible: boolean }> = []

  @Watch('questionsFrontend')
  onQuestionsChange(questions):void {
    this.processQuestionsToModel(questions)
  }

  get selectedOption(): any {
    if (this.message.type === 'Quiz') {
      return [ this.message.correct_option_id ]
    }
  }

  set selectedOption(option) {
    if (this.message.type === 'Quiz') {
      this.message.correct_option_id = option.join('')
    }
  }

  processQuestionsToModel(questions:Array<{ value: string, visible: boolean }>):void {
    this.message.questions = questions.map(q => q.value)
  }

  handleQuestionItemEditClick(option: { value: string, visible: boolean }, idx:number):void {
    option.visible = !option.visible

    this.questionsFrontend = this.questionsFrontend.map((value, index) => {
      if (idx === index) {
        return option
      }
      return value
    })

    if (this.message.type === PostType.Quiz) {
      this.message.correct_option_id = ''
    }
  }

  addNewOption() {
    if (!this.questionsFrontend.length || (this.questionsFrontend.length && !this.questionsFrontend.find(q => q.value === this.newOption))) {
      this.questionsFrontend.push({ value: this.newOption, visible:false })
    }
    this.newOption = ''
  }

  explanationValues() {
    let options = [ ...this.message.questions ]
    return options.map((v: string) => {
      return { label: v, value: v }
    })
  }

  isCheckboxDisabled(option: string): boolean {
    if (this.message.type === PostType.Quiz) {
      return (this.message.correct_option_id.length >= 1 && this.message.correct_option_id.indexOf(option) === -1) || this.disabled
    }

    return true
  }

  mounted():void {
    this.questionsFrontend = this.message.questions.map(value => ({
      value,
      visible: false
    }))
  }
}
