

































































































import { PostType, Style } from '@/includes/types/Post.types'
import { unsavedMessage } from '@/includes/AccountDataHelper'
import { BoardService } from '@/includes/services/BoardService'
import { errorNotification } from '@/includes/services/NotificationService'
import { BoardConfig, BoardPermissions } from '@/includes/types/Board.types'
import { canCreatePost } from '@/includes/PermissionHelper'

import { Component, Emit, Prop, VModel, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import store from '@/store/store'

@Component({
  data() {
    return {
      PostType,
      canCreatePost
    }
  }
})
export default class PostTypePicker extends Vue {
  @VModel() isPostPickerOpen!: boolean

  @Prop({ default: null }) activeBoard!: string | null

  @Prop({ type: Array, default: null }) activePermissions!: Array<BoardPermissions> | null

  @Prop({ type: String, default: null }) activeChannel!: string | null

  @Prop({ type: Boolean }) draftsOnly!: boolean

  @Emit('onPostTypeSelect')
  onPostTypeSelect(type: PostType, query: Record<string, any> | null = null): { type: PostType, query: Record<string, any> | null } {
    return { type, query }
  }

  @Emit('onModalClose')
  onModalClose(): boolean {
    this.boardConfig = null

    return true
  }

  @Emit('onCreateTemplateButtonClick')
  createTemplateButtonClick(): string | null {
    return this.activeBoard
  }

  boardConfig: BoardConfig | null = null

  loading = false

  @Watch('activeBoard')
  async onActiveBoardChange(boardId: string | null): Promise<void> {
    if (boardId) {
      try {
        this.loading = true
        this.boardConfig = await BoardService.getPostBoardConfig('tg', { board_key: boardId })
      } catch (e) {
        errorNotification(e)
      } finally {
        this.loading = false
      }
    }
  }

  get stylesExists(): boolean {
    return !!this.styles && !!Object.keys(this.styles).length
  }

  get styles(): Record<string, Style> | null {
    if (this.$store.state.boardsState.activeBoard && this.$store.state.boardsState.activeBoard.config.styles !== null) {
      return this.$store.state.boardsState.activeBoard.config.styles
    }

    return this.boardConfig && this.boardConfig.config.styles
  }

  // get unsavedType() {
  //   if (this.activeBoard) {
  //     return unsavedMessageType(this.activeBoard.split('.')[0])
  //   }
  //   return unsavedMessageType(this.$route.params.id)
  // }

  get unsavedMessageData() {
    return unsavedMessage(this.$route.params.id ?? this.activeBoard!.split('.')[0])
  }

  get postTypes() {
    return [
      { type: PostType.Post, title: this.$t('post_type_post').toString(), icon: 'file-text' },
      { type: PostType.Paid, title: this.$t('post_type_payed').toString(), icon: 'dollar' },
      { type: PostType.Poll, title: this.$t('post_type_poll').toString(), icon: 'bars' },
      { type: PostType.Quiz, title: this.$t('post_type_quiz').toString(), icon: 'bulb' },
    ]
  }

  handlePostTypeClick(type: PostType, styleId?: string): void {
    let query = {} as Record<string, string>

    if (styleId) {
      query.styleId = styleId
    }

    if (!canCreatePost(this.activePermissions)) {
      query.suggest = 'true'
    }
    if (this.activeChannel) {
      query.channel = this.activeChannel
    }

    this.onPostTypeSelect(type, query)
  }
}
