




















































































































import PageTitle from '@/components/PageTitle.vue'
import { PayedPostMessage, Poll, PostMessage, PostType, Quiz } from '@/includes/types/Post.types'
import { InputSetups } from '@/mixins/input-setups'
import Quizes from '@/components/Quizes.vue'
import PostTypePicker from '@/components/Post/PostTypePicker.vue'
import { successNotification } from '@/includes/services/NotificationService'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import DrawerWidthMixin from '@/includes/DrawerWidthMixin'
import { GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'

import Component from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  components: {
    Quizes,
    PageTitle,
    EmptyData,
    PostTypePicker,
  },
  data() {
    return {
      EditorButtonsHelpView
    }
  },
  computed: {
    ...mapGetters([ 'getBoardById' ])
  }
})
export default class DraftsList extends Mixins<InputSetups, UseFields, DrawerWidthMixin>(InputSetups, UseFields, DrawerWidthMixin) {
  getBoardById!:GetBoardByIdType

  selectedDraft: null | any = {}

  isActive = false

  isMounted = false

  isLoading = false

  isPostTypeModalOpen = false

  draftToCreate: Record<string, string> | null = null

  get draftPosts(): Array<{ key: { token_code: string, token_id: number }, message: PostMessage | Poll | Quiz }>  {
    return this.$store.state.draftsState.drafts || []
  }

  get showDraftsOnly():boolean {
    return !!this.draftToCreate
  }

  @Watch('isPostTypeModalOpen')
  isPostTypeModalOpenChange(val:boolean):void {
    if (!val) {
      setTimeout(() => {
        this.draftToCreate = null
      }, 200)
    }
  }

  handleTemplateButtonClick():void {
    this.$router.push({
      name: "Style",
      params: {
        actionType: BaseItemViewAction.New
      }
    })
  }

  textPreview(message: PostMessage | Poll | Quiz | PayedPostMessage): string {
    if (message.type === PostType.Post || message.type === PostType.Paid) {
      const text = message.variants[0].text

      if (text.length > 50) {
        return `${ message.variants[0].text.slice(0, 50) }...`
      }
      
      return text
    }

    return message.text
  }

  onClose(): void {
    this.isActive = false
    this.selectedDraft = null
  }

  postTypeSelectHandler(data: { type: PostType, query: Record<string, any> | null }) {
    this.$router.push({
      name: 'post',
      params: {
        actionType: 'new',
      },
      query: {
        type: data.type.toLowerCase(),
        ...data.query,
        ...this.draftToCreate !== null ? {
          draft:this.draftToCreate.draftId
        }: {},
      },
    })
  }

  createFromDraft():void {
    this.isPostTypeModalOpen = false
    this.$router.push({
      name: 'post',
      params: {
        actionType: 'new',
      },
      query: {
        type: this.draftToCreate!.type.toLowerCase(),
        draft: this.draftToCreate!.draftId
      },
    })
  }

  deleteDraftClickHandler({ token_code, token_id }: { token_code: string, token_id: number }) {
    this.$confirm({
      title: this.$t('draft_confirm_remove').toString(),
      content: this.$t('draft_confirm_remove_text').toString(),
      okText: this.$t('accept').toString(),
      okType: 'danger',
      cancelText: this.$t('reject').toString(),
      onOk: async () => {
        this.isLoading = true
        const post_key = token_id + '.' + token_code

        await this.$store.dispatch('removeDraft', post_key)

        successNotification()
        this.isLoading = false
      },
      centered: true
    })
  }

  handleSelect(post: any): void {
    this.selectedDraft = post.message
    this.isActive = true
  }

  goToCreatePost(): void {
    this.isPostTypeModalOpen = true
  }

  createPostFromDraft(draft:any): void {
    this.draftToCreate = {}
    this.$set(this.draftToCreate, 'draftId', draft.key.token_id.toString())
    this.$set(this.draftToCreate, 'type', draft.message.type.toLowerCase())

    this.isPostTypeModalOpen = true
  }

  draftTitle(index: number): string {
    return `${ this.$t('board_draft_list_index') } № ${ index + 1 }`.toString()
  }

  mounted(): void {
    if (!this.$store.state.draftsState.drafts) {
      this.$baseTemplate.loader.open()

      this.$store.dispatch('requestDrafts')
        .finally(() => this.$baseTemplate.loader.close())
    }

    this.initializeSidebarWidth(650)
  }
}
